/** Common styles for highlight, annotation and selection layers and native browser selection style */

/* Search result highlight  */
.Attention {
  position: absolute;
  background-color: #00336633;
  mix-blend-mode: darken;
}

/* Selection boxes container layer style */
.textLayer {
  opacity: 1 !important;
}

/* Browser selection color for selection boxes container layer  */
.textLayer ::selection {
  background: rgba(0, 51, 102, 0.2) !important;
}

.pdfViewer .page {
  margin-bottom: 15px !important;
}

.selectionLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  line-height: 1;
}
.selectionLayer > div {
  position: absolute;
  background: transparent;
  cursor: text;
}

.selectionLayer > div.visible,
.selectionLayer > span.visible {
  background: rgba(0, 51, 102, 0.2) !important;
}
